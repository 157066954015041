import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import MyContext from "../ContextApi/MyContext";

export default function Blog() {
  const { filterPosts, posts, uniqueCategory, setCategory } = useContext(MyContext)

  return (
    <div className="ps-0" style={{ overflowX: "hidden" }}>
      <Helmet>
        <title>Blogs - Technic Mentors</title>
        {/* open grapgh tag */}
        <meta property="og:title" content="Blog" />
        <meta property="og:description" content="Explore insights, tips, and latest trends in IT on Technic Mentors blog. Stay informed and inspired with our expert articles and updates on tech innovations." />
        <meta property="og:url" content="https://technicmentors.com/blog" />
        <meta property="og:type" content="website" />

        {/* twitter card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@TechnicMentors" />
        <meta name="twitter:title" content="Blog" />
        <meta name="twitter:description" content="Explore insights, tips, and the latest trends in IT on the Technic Mentors blog. Stay informed and inspired with our expert articles and updates on tech innovations." />

        {/* Schema.org structured data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://technicmentors.com/blog",
            "name": "Capobrain",
            "description": "Explore insights, tips, and the latest trends in IT on the Technic Mentors blog. Stay informed and inspired with our expert articles and updates on tech innovations." ,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://capobrain.com/search?q={search_term_string}",
                "actionPlatform": [
                  "https://schema.org/DesktopWebPlatform",
                  "https://schema.org/IOSPlatform",
                  "https://schema.org/AndroidPlatform"
                ]
              },
              "query-input": "required name=search_term_string"
            }
          }
        `}</script>

        <link rel="canonical" href="https://technicmentors.com/blog" />
        <meta name="description" content="Explore insights, tips, and latest trends in IT on Technic Mentors blog. Stay informed and inspired with our expert articles and updates on tech innovations." />
      </Helmet>
      <div className="home-container">
        <div
          className="allHeaderBg"
        >
          <div className="allHeader-overlay d-flex align-items-center justify-content-center">
            <div>
              <h1
                className="head text-center text-white animate__animated animate__zoomIn allHeaderP"
              >
                Blog Section
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3 d-flex justify-content-between">
        <div className="col-md-3">
          <div
            className="background-img3"
          >
            <div className="color-overlay3 pb-3 pt-2 outlet">
              {posts &&
                [...uniqueCategory].map((category) => {
                  return (
                    <div className="text-dark px-2" key={category}>
                      <button
                        className="btn btn-outline-primary w-100 text-dark mt-2"
                        onClick={() => setCategory(category)}
                      >
                        <div className="text-start">
                          {category}
                        </div>
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div className="container">
            <p className="text-center p-2" style={{ backgroundColor: "#e3eaf7", display: "inline-block" }}> <strong> Note </strong>: Get insights about the tech world in English and Urdu languages at <a href="https://urdustem.com/" target="blank" rel="noopener"> Urdu Stem</a>.</p>
            <div className="row">
              {filterPosts.map((post) => (
                <div className="col-md-4 col-12 mt-3 mb-3" key={post._id}>
                  <Link style={{ textDecoration: "none" }} to={`/blog/${post.slug}`}>
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title">
                          {post.title.length > 40 ? post.title.slice(0, 40) + "..." : post.title}
                        </h5>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
